import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cardApi } from './cards.api'
import { ICardOnboardingStep } from './cards.type'

export interface ICardsState {
  fundingAccount: {
    status: string
  }
  isDisabled: boolean
  cards: any[]
  isSkippedOnboarding: boolean
  isOnboardingCompleted: boolean
}

const initialState: ICardsState = {
  isDisabled: false,
  fundingAccount: {
    status: undefined
  },
  cards: [],
  isSkippedOnboarding: false,
  isOnboardingCompleted: false
}

export const cardsSlice = createSlice({
  name: 'cards-slice',
  initialState,
  reducers: {
    setIsDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload
    },
    setIsSkippedOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isSkippedOnboarding = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(cardApi.endpoints.getOrganizationCards.matchFulfilled, (state, { payload }) => {
        state.cards = payload || []
      })
      .addMatcher(cardApi.endpoints.getCardOnboardingSteps.matchFulfilled, (state, { payload }) => {
        state.isOnboardingCompleted = payload?.status === 'completed'
      })
      .addMatcher(cardApi.endpoints.getCardFundingAccount.matchFulfilled, (state, { payload }) => {
        state.fundingAccount = {
          status: payload?.[0]?.status
        }
      })
  }
})

export const { setIsDisabled, setIsSkippedOnboarding } = cardsSlice.actions
