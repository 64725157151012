import { AuthenticatedView } from '@/components-v2/templates'
import { NextPageWithLayout } from '@/pages/_app'
import WalletList from '@/views/Wallets/SourceOfFunds'

export const WHITELIST_ENV = ['localhost', 'development', 'staging', 'production'] // TODO: TO DEPRECATE ONCE ALL DEPENDENCIES IN VARIOUS PLACES ARE REMOVED

const Page: NextPageWithLayout = () => <WalletList />

Page.Layout = function getLayout(page) {
  return <AuthenticatedView>{page}</AuthenticatedView>
}

export default Page
