import { Text } from '@mantine/core'

const BalanceCell = ({ data, isWalletSyncing, fiatCurrency }) =>
  isWalletSyncing ? (
    <div className="skeleton skeleton-text mt-1" style={{ width: 120 }} />
  ) : (
    <Text>
      {fiatCurrency?.symbol}
      {data.price} {fiatCurrency?.code}
    </Text>
  )

export default BalanceCell
