import Image from 'next/legacy/image'
import { FC, useEffect, useState } from 'react'
import HqLogo from '@/public/svg/logos/hq-logo-1.svg'
import HqLogoSmall from '@/public/svg/logos/hq-logo.svg'
import JournalEntryDialogue from './JournalEntryDialogue'
import { useOrganizationId } from '@/utils/getOrganizationId'
import CountryCurrency from './CountryCurrency'
import { useAppSelector, useAppDispatch } from '@/state'
import { orgSettingsSelector } from '@/slice/orgSettings/orgSettings-slice'
import { accountingIntegrationSelector } from '@/slice/org-integration/org-integration-selector'
import ExportsDialog from './ExportsDialog'
import { selectFeatureState } from '@/slice/feature-flags/feature-flag-selectors'
import PropfilePopup from '../Sidebar-v2/ProfilePopup'
import { Burger } from '@mantine/core'
import { setIsMobileDrawerOpen } from '@/slice/platform/platform-slice'
import { selectIsMobileDrawerOpen } from '@/slice/platform/platform.selectors'

export interface IPropsTopBar {
  user: {
    firstName: string
    lastName: string
    loginCredentials: string
    role: string
  }
  onClickLogout: () => void
  onClickNavigateToProfile: () => void
  isLoading?: boolean
  refetch?: () => void
}

const TopAppBar: FC<IPropsTopBar> = ({ user, onClickLogout, onClickNavigateToProfile, isLoading = false, refetch }) => {
  const organizationId = useOrganizationId()
  const dispatch = useAppDispatch()
  const isMobileDrawerOpen = useAppSelector(selectIsMobileDrawerOpen)
  const accountingIntegration = useAppSelector(accountingIntegrationSelector)
  const isNewExportsCSVEnabled = useAppSelector((state) => selectFeatureState(state, 'isNewExportsCSVEnabled'))
  const [isRunning, setIsRunning] = useState(false)
  useEffect(() => {
    if (!isRunning) {
      refetch()
    }
  }, [isRunning])

  const toggleRunning = (state) => {
    setIsRunning(state)
  }

  const { fiatCurrency: fiatCurrencySetting, country: countrySetting } = useAppSelector(orgSettingsSelector)

  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false)

  const handleOnClickprofileDropdown = () => setIsProfilePopupOpen(!isProfilePopupOpen)

  const handleOnClickBurger = () => {
    dispatch(setIsMobileDrawerOpen(!isMobileDrawerOpen))
  }
  return (
    <div className="flex justify-between items-center px-4 h-[68px]  w-full z-10 ">
      <div>
        <div className="block lg:hidden">
          <Image src={HqLogo} height={48} width={168} />
        </div>
        <div className="hidden lg:block ">
          <div className="flex items-center gap-3">
            <Burger color="black" opened={isMobileDrawerOpen} onClick={handleOnClickBurger} />
            <Image src={HqLogoSmall} height={40} width={58} />
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center">
        <div className="block lg:hidden">
          {countrySetting?.iso && (
            <CountryCurrency
              country={countrySetting}
              currency={fiatCurrencySetting?.code}
              organizationId={organizationId}
            />
          )}
        </div>
        <div className="block lg:hidden">
          {!isLoading &&
            !isNewExportsCSVEnabled &&
            accountingIntegration &&
            accountingIntegration?.status === 'completed' && (
              <JournalEntryDialogue accountingIntegration={accountingIntegration} />
            )}
        </div>

        <div className="block lg:hidden">
          {(!isLoading || accountingIntegration) && isNewExportsCSVEnabled && (
            <ExportsDialog accountingIntegration={accountingIntegration} toggleRunning={toggleRunning} />
          )}
        </div>

        <div>
          <PropfilePopup
            isOpen={isProfilePopupOpen}
            onClick={handleOnClickprofileDropdown}
            user={user}
            onClickLogout={onClickLogout}
            handleNavigateToProfile={onClickNavigateToProfile}
          />
        </div>
      </div>
    </div>
  )
}
export default TopAppBar
