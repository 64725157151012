import { Checkbox, CloseButton, Combobox, Group, InputBase, Text, useCombobox } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import React, { useState } from 'react'

type Option = { value: string; label: string; [key: string]: any }

interface MultiSelectCheckboxProps {
  data: Option[]
  name?: string
  onChange?: (value: string[]) => void
  onClear?: () => void
  width?: number
  placeholder?: string
}

const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = ({
  data,
  name,
  width = 250,
  onChange,
  onClear,
  placeholder
}) => {
  const [search, setSearch] = useState('')

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch('')
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })

  const [value, setValue] = useState<string[]>([])

  const handleValueSelect = (val: string) => {
    const newVal = value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
    setValue(newVal)
    if (onChange) onChange(newVal)
  }

  const handleClear = () => {
    setValue([])
    if (onClear) onClear()
  }

  const options = data
    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
        <Group gap="sm">
          <Checkbox
            checked={value.includes(item.value)}
            aria-hidden
            tabIndex={-1}
            style={{ pointerEvents: 'none' }}
            onChange={() => {
              // console.log()
            }}
          />
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ))

  const _name = name ? (value.length > 1 ? `${name}s ` : `${name} `) : ''

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
      <Combobox.Target>
        <InputBase
          w={width}
          component="button"
          type="button"
          pointer
          rightSection={
            value.length > 0 ? (
              <CloseButton size={14} radius="xl" bg="gray.1" onClick={handleClear} />
            ) : (
              <Combobox.Chevron />
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={value.length > 0 ? 'auto' : 'none'}
        >
          <Text size="sm">{value.length > 0 ? `${value.length} ${_name}Selected` : placeholder || 'Select'}</Text>
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          leftSection={<IconSearch size={14} />}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search"
        />
        <Combobox.Options>
          {options.length > 0 ? options : <Combobox.Empty>No option found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default MultiSelectCheckbox
