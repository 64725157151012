export enum EOptions {
  OPTIONS = 'Options',
  WALLET = 'Wallet',
  SAFE = 'Safe',
  ACCOUNT = 'Account'
}

export enum EProcessStatus {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  REJECTED = 'Rejected'
}

export enum EWalletTab {
  WALLETS = 'Wallets',
  GROUPS = 'Wallet Groups'
}

export const walletTabs = [
  {
    value: EWalletTab.WALLETS,
    label: 'Wallets'
  },
  {
    value: EWalletTab.GROUPS,
    label: 'Wallet Groups'
  }
]
