import { useOrganizationId } from '@/utils/getOrganizationId'
import { useRouter } from 'next/router'
import { BaseModal } from '../Modals/BaseModal'
import { useSendAnalysisMutation } from '@/api/analysis-api'
import CheckIcon from '@/public/svg/icons/check-icon.svg'
import Image from 'next/legacy/image'
import { Button, Flex, Stack, Text, Title } from '@mantine/core'

const WelcomeFreeTrialModal = ({ provider, orgName }) => {
  const router = useRouter()
  const organisationId = useOrganizationId()
  const [triggerSendAnalysis] = useSendAnalysisMutation()

  const handleRedirectToSeePlan = () => {
    router.push(`/${organisationId}/orgsettings?activeTab=pricingAndPlans`)
    provider.methods.setIsOpen(false)
    triggerSendAnalysis({
      eventType: 'SEE_PLANS_FREE_TRIAL_MODAL',
      metadata: {
        organizationId: organisationId
      }
    })
  }

  return (
    <BaseModal
      provider={provider}
      classNames="rounded-3xl w-[610px] lg:w-full lg:h-full max-h-full lg:rounded-none overflow-auto"
    >
      <BaseModal.Header extendedClass="lg:px-6 lg:pt-4 justify-end">
        <BaseModal.Header.CloseButton
          onClose={() => {
            window.history.replaceState(null, '', `/${router.query.organizationId}/dashboard`)
            triggerSendAnalysis({
              eventType: 'CLOSE_FREE_TRIAL_MODAL',
              metadata: {
                organizationId: organisationId
              }
            })
          }}
        />
      </BaseModal.Header>
      <BaseModal.Body extendedClass="lg:px-6 lg:pb-4 lg:flex-1">
        <Stack gap={32} className="mb-6 mt-8 lg:mt-12">
          <Title order={1} px={8} className="text-center">
            Your 30-day free trial has started for {orgName}!
          </Title>
          <Text size="lg">Enjoy our powerful features for 30 days, completely free.</Text>
          <Stack gap={16}>
            <Flex align="self-start" gap={12}>
              <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                <Image src={CheckIcon} width={12} height={12} />
              </div>
              <Text flex={1}>Import unlimited wallets</Text>
            </Flex>
            <Flex align="self-start" gap={12}>
              <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                <Image src={CheckIcon} width={12} height={12} />
              </div>
              <Text flex={1}>Manage up to 10,000 transactions</Text>
            </Flex>
            <Flex align="self-start" gap={12}>
              <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                <Image src={CheckIcon} width={12} height={12} />
              </div>
              <Text flex={1}>Monitor your assets</Text>
            </Flex>
            <Flex align="self-start" gap={12}>
              <div className="p-1 rounded-full bg-[#FDF455] flex items-center">
                <Image src={CheckIcon} width={12} height={12} />
              </div>
              <Text flex={1}>Integrate with your accounting softwares (Xero, QuickBooks)</Text>
            </Flex>
            <Text flex={1}>and much more!</Text>
          </Stack>
          <Text size="lg" className="text-center lg:hidden" fw={600}>
            Want to buy a plan? Choose a plan that works best for your business.
          </Text>
          <Button
            color="#FCF22D"
            c="dark"
            fw={500}
            className="lg:!hidden"
            variant="filled"
            onClick={handleRedirectToSeePlan}
          >
            See Plans
          </Button>
          <Button
            color="#FCF22D"
            c="dark"
            fw={500}
            mt={16}
            className="default-lg:!hidden"
            variant="filled"
            onClick={() => {
              provider.methods.setIsOpen(false)
            }}
          >
            Proceed to dashboard
          </Button>
        </Stack>
      </BaseModal.Body>
    </BaseModal>
  )
}

export default WelcomeFreeTrialModal
