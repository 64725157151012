import TokenList from '@/components-v2/molecules/TokenList/TokenList'

const AssetCell = ({ data, isWalletSyncing }) =>
  isWalletSyncing ? (
    <div className="skeleton skeleton-text mt-1" style={{ width: 120 }} />
  ) : (
    <TokenList
      tokens={
        data?.assets?.map((asset) => ({
          id: asset.publicId,
          imageUrl: asset.imageUrl || '',
          name: asset.symbol
        })) || []
      }
      id={data?.id}
    />
  )

export default AssetCell
