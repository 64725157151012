// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const shouldSendToSentry = ['localhost', 'development', 'staging', 'production'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: shouldSendToSentry,
  tracesSampleRate: 1.0,
  integrations: (integrations) => integrations.filter((integration) => integration.name !== 'Dedupe'), // Allow duplicates
  attachStacktrace: true, // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#attach-stacktrace
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  debug: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production', // SDK will attempt to print out useful debugging information if something goes wrong with sending the event
  maxBreadcrumbs: 50, // The maximum number of breadcrumbs sent with events. Default: 100
  autoSessionTracking: true, // Automatically capture and report sessions https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/options/#auto-session-tracking
  beforeBreadcrumb(breadcrumb, hint) {
    // Ignore all click events
    // if (breadcrumb.category === 'fetch') {
    //   return null
    // }

    if (breadcrumb.category === 'ui.click') {
      return null
    }

    if (breadcrumb.category === 'xhr') {
      return null
    }
    return breadcrumb
  }
})
