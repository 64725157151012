/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
import { Button } from '@/components-v2'
import FormErrorLabel from '@/components/FormErrorLabel/FormErrorLabel'
import warning from '@/public/svg/light-warning-icon.svg'
import { EOrganizationType } from '@/slice/organization/organization.types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select, Text, TextInput, Title } from '@mantine/core'
import Image from 'next/image'
import { FC, useState } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import ContactDropdown from './ContactDropdown'

interface ICreateOrgInfoCard {
  onClickSubmit: any
  onClickBack?: any
  height?: string
  width?: string
  className?: string
  title?: string
  nonSubtitle?: boolean
  onBack?: () => void
  renderBackBtn?: boolean
  btnClassName?: string
}

export interface ICreateOrgForm {
  name: string
  type: EOrganizationType
  role: string
  contacts: { provider: string; content: string }[]
}

export const validateEmail = (email) => {
  const emailRegex =
    /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/gm
  if (!emailRegex.test(email)) {
    return false
  }
  return true
}

const validateWhatsapp = (value) => {
  const whatsApp =
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
  if (!whatsApp.test(value)) {
    return false
  }
  return true
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter a name for your organisation'),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        provider: Yup.string().required(),
        content: Yup.string().trim()
      })
    )
    .test(
      'checkContactRequired',
      'Please provide at least one point of contact for your personalised onboarding experience',
      (value) => value.some((contact) => contact.content.trim() !== '')
    )
    .test('validEmail', 'Please provide a valid email', (value) => {
      for (const item of value) {
        if (item.provider === 'Email' && item.content.trim() && !validateEmail(item.content.trim())) return false
      }
      return true
    })
    .test('validWhatsapp', 'Please provide a valid contact number', (value) => {
      for (const item of value) {
        if (item.provider === 'WhatsApp' && item.content.trim() && !validateWhatsapp(item.content.trim())) return false
      }
      return true
    })
})

const CreateOrgCard: FC<ICreateOrgInfoCard> = ({
  onClickSubmit,
  height = 'w-full',
  width = 'w-full',
  className,
  title,
  nonSubtitle,
  onBack,
  renderBackBtn,
  btnClassName
}) => {
  // const user = useAppSelector(userInfoSelector)

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<ICreateOrgForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type: EOrganizationType.DAO,
      name: '',
      role: '',
      contacts: [{ content: '', provider: 'Telegram' }]
    }
  })

  const [isClickSubmit, setIsClickSubmit] = useState(false)

  const role = useWatch({ control, name: 'role' })
  const {
    fields: contactFields,
    append: contactAppend,
    remove: contactRemove
  } = useFieldArray<ICreateOrgForm>({ control, name: 'contacts', keyName: 'id' })

  const handleSelectRole = (selectedRole) => {
    setValue('role', selectedRole)
  }

  // const handleOnClickBack = () => {
  //   reset()
  //   onClickBack()
  // }

  const handleAddAnotherContact = () => {
    contactAppend({
      provider: 'Telegram',
      content: ''
    })
  }

  const handleOnClickRemoveContact = (_index) => {
    const contacts = getValues('contacts')
    if (contacts.length > 1) {
      contactRemove(_index)
    } else {
      setValue('contacts.0.provider', 'Telegram')
      setValue('contacts.0.content', '')
    }
  }

  const rolesOptions = [
    { value: 'Chief Financial Officer', label: 'Chief Financial Officer (CFO)' },
    { value: 'Chief Executive Officer', label: 'Chief Executive Officer (CEO)' },
    { value: 'Operations Manager', label: 'Operations Manager' },
    { value: 'Finance Manager', label: 'Finance Manager' },
    { value: 'Accountant', label: 'Accountant' },
    { value: 'Financial Analyst', label: 'Financial Analyst' },
    { value: 'Other', label: 'Other' }
  ]

  return (
    <div
      className={`${width} ${height} m-8 flex items-center justify-center bg-white font-inter rounded-3xl shadow-home-modal p-8 ${className}`}
    >
      <form onSubmit={handleSubmit(onClickSubmit)} className="h-full lg:px-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <Title order={1} className="text-center lg:!text-[30px]">
              {title || 'Welcome to HQ.xyz!'}
            </Title>
          </div>
          {!nonSubtitle && (
            <Text size="lg" mt={4} mb={32} className="text-center">
              Now that you’ve created your account, let’s set up your Organisation.
            </Text>
          )}
        </div>

        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextInput
              classNames={{ label: 'font-semibold !text-sm lg:!text-xs' }}
              size="sm"
              label="Organisation Name"
              placeholder="Enter organisation name"
              {...field}
              withAsterisk
              error={errors?.name?.message}
            />
          )}
        />

        <Select
          mt={32}
          comboboxProps={{ withinPortal: false }}
          classNames={{ root: 'flex flex-col gap-1', label: 'lg:!text-xs' }}
          label="Your Role (in this organisation)"
          description="This will help us provide a tailored experience based on your role."
          placeholder="Select your role in the organisation"
          value={role || null}
          data={rolesOptions}
          onChange={handleSelectRole}
        />

        {errors?.role?.message && (
          <div className="text-xs font-normal flex items-center text-error-500 mt-1 mx-1">
            <div className="mr-2 flex items-center">
              <Image src={warning} alt="warning" width={11} height={11} />
            </div>
            {errors?.role?.message}
          </div>
        )}

        <Text fw={500} mt={32} mb={4} className="lg:!text-xs">
          Get a free and personalised onboarding experience{' '}
          <Text component="span" c="red.6">
            *
          </Text>
        </Text>
        <Text c="dimmed" size="sm" mb={4}>
          We would like to onboard you and your team properly. Please provide at least one point of contact for our team
          to reach out.
        </Text>

        <div className="flex flex-col gap-3">
          {contactFields.map((_, index) => (
            <div key={index}>
              <ContactDropdown
                onClickRemoveContact={handleOnClickRemoveContact}
                watch={watch}
                setValue={setValue}
                trigger={trigger}
                index={index}
                key={index}
                removeable={index >= 1}
                clearErrors={clearErrors}
                setError={setError}
              />
              <FormErrorLabel
                error={errors?.contacts?.[index]?.provider?.message || errors?.contacts?.[index]?.content?.message}
              />
            </div>
          ))}
          {errors?.contacts?.message && isClickSubmit && (
            <div className="text-xs font-normal flex items-center text-error-500 mt-1 mx-1">
              <div className="mr-2 flex items-center">
                <Image src={warning} alt="warning" width={11} height={11} />
              </div>
              {errors?.contacts?.message}
            </div>
          )}
        </div>
        <Button onClick={handleAddAnotherContact} className="mt-3" variant="contained" size="sm" color="white">
          + Add Another Contact
        </Button>
        <div className={`mt-8 flex gap-4 ${btnClassName}`}>
          {renderBackBtn && (
            <Button
              color="secondary"
              size="lg"
              onClick={() => {
                if (onBack) onBack()
              }}
            >
              Back
            </Button>
          )}
          <Button
            type="submit"
            fullWidth
            color="primary"
            size="lg"
            onClick={() => {
              setIsClickSubmit(true)
            }}
          >
            Create Organisation
          </Button>
        </div>
      </form>
    </div>
  )
}
export default CreateOrgCard
