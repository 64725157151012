import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const CardIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 24,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 26
}) => (
  <svg width={width} height={height} fill={fill} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 4.66683H1.33337M7.33337 7.3335H4.00004M1.33337 3.46683L1.33337 8.53349C1.33337 9.28023 1.33337 9.6536 1.4787 9.93881C1.60653 10.1897 1.8105 10.3937 2.06139 10.5215C2.3466 10.6668 2.71997 10.6668 3.46671 10.6668L12.5334 10.6668C13.2801 10.6668 13.6535 10.6668 13.9387 10.5215C14.1896 10.3937 14.3936 10.1897 14.5214 9.93882C14.6667 9.6536 14.6667 9.28023 14.6667 8.5335V3.46683C14.6667 2.72009 14.6667 2.34673 14.5214 2.06151C14.3936 1.81063 14.1896 1.60665 13.9387 1.47882C13.6535 1.3335 13.2801 1.3335 12.5334 1.3335L3.46671 1.3335C2.71997 1.3335 2.3466 1.3335 2.06139 1.47882C1.8105 1.60665 1.60653 1.81063 1.4787 2.06151C1.33337 2.34672 1.33337 2.72009 1.33337 3.46683Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CardIcon
