import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getAccessToken } from '@/utils/localStorageService'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { isMonetisationEnabled } from '@/config/constants'
import { SubscriptionStatus, useGetSubscriptionQuery } from '@/api/subscription-api'

export const PUBLIC_PATH = [
  '/authenticate',
  '/multisend',
  '/invoice',
  '/signin',
  '/invite',
  '/signup',
  '/payments',
  '/integrations'
]
export const PRIVATE_PATH = ['/organisation']
const PUBLIC_PATH_NAMES = ['/[organizationId]']
const EMPLOYEE_WHITELIST_ROUTES = ['/account', '/profile']

export function RouteGuard({ children, userRole }) {
  const router = useRouter()
  // const { login } = useAuth()
  const activeOrganizationId = useOrganizationId()
  const {
    data: subscriptionPlan,
    isLoading,
    isFetching
  } = useGetSubscriptionQuery(
    { organizationId: activeOrganizationId },
    { skip: !activeOrganizationId || PUBLIC_PATH_NAMES.includes(router.pathname) }
  )

  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    authCheck(router.asPath)
    router.events.on('routeChangeComplete', authCheck)
    return () => {
      router.events.off('routeChangeComplete', authCheck)
    }
  }, [userRole])

  useEffect(() => {
    const path = router.pathname.split('/')[2]
    if (
      !['transfer', 'pendingApproval', 'contacts', 'orgsettings'].includes(path) &&
      isMonetisationEnabled &&
      subscriptionPlan?.status === SubscriptionStatus.EXPIRED &&
      !isLoading &&
      !isFetching
    ) {
      router.push(`/${activeOrganizationId}/orgsettings?activeTab=pricingAndPlans`)
    }
  }, [activeOrganizationId, router.pathname, subscriptionPlan?.status, isLoading, isFetching])

  function authCheck(url: string) {
    const accessToken = getAccessToken()

    const path = url.split('?')[0]
    const isPrivateRoute = /\/([^/])+\/./.test(path) || PRIVATE_PATH.find((item) => path.includes(item))
    const isHomePage = path === '/'
    const isEmployeeAllowed = [...EMPLOYEE_WHITELIST_ROUTES, ...PUBLIC_PATH].find((route) => path.includes(route))

    if (path === '/signin' && accessToken) {
      router.push('/')
      return
    }

    if (userRole === 'Employee' && !isEmployeeAllowed && activeOrganizationId && accessToken) {
      router.push(`/${activeOrganizationId}/profile`)
      return
    }

    if (isHomePage || (isPrivateRoute && accessToken) || !isPrivateRoute || isEmployeeAllowed) {
      setAuthorized(true)
      // connectMetamask()
    } else {
      setAuthorized(false)
      router.push('/')
    }
  }

  return authorized && children
}
