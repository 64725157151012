/* eslint-disable no-continue */
import { ROUTES } from './side-bar-links'
import { SubscriptionStatus } from '@/api/subscription-api'

interface IGetRoutesParams {
  isMobile: boolean
  organizationId: string
  planStatus: string
}

const getEnabled = (route, organizationId, currentEnvironment, isMobile) => {
  const isEnvEnabled = Boolean(
    !route.whitelistEnvironment ||
      route?.whitelistOrganizations?.includes(organizationId) ||
      (route?.whitelistEnvironment?.length && route.whitelistEnvironment.includes(currentEnvironment))
  )

  const isDeviceEnabled =
    !isMobile || (isMobile && route.whitelistedDevices && route?.whitelistedDevices.includes('mobile'))

  return isEnvEnabled && isDeviceEnabled
}

export const getRoutes = ({ isMobile, organizationId, planStatus }: IGetRoutesParams) => {
  const filteredRoutes = []
  const currentEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT

  for (const routeGroup of ROUTES) {
    const isRouteGroupEnabled = getEnabled(routeGroup, organizationId, currentEnvironment, isMobile)
    if (!isRouteGroupEnabled) continue

    const filteredRouteGroup = {
      title: routeGroup.title,
      routes: []
    }

    for (const route of routeGroup.routes) {
      const isRouteEnabled = getEnabled(route, organizationId, currentEnvironment, isMobile)
      if (!isRouteEnabled) continue

      // Disable links for expired gated experience
      const isRouteExpired =
        !route?.children?.length &&
        planStatus === SubscriptionStatus.EXPIRED &&
        !['/transfer', '/pendingApproval', '/contacts', '/orgsettings', '/transact/drafts'].includes(route.path)

      if (isRouteExpired) {
        filteredRouteGroup.routes.push({ ...route, active: false })
        continue
      }

      // Route with children
      if (route.children?.length > 0) {
        const { children } = route
        const routeData = {
          ...route,
          children: []
        }
        for (const child of children) {
          const isChildRouteEnabled = getEnabled(child, organizationId, currentEnvironment, isMobile)
          if (!isChildRouteEnabled) continue

          const isChildRouteExpired =
            planStatus === SubscriptionStatus.EXPIRED &&
            ['/transact/drafts', '/transact/payment-history'].includes(child.path)

          if (isChildRouteExpired) {
            routeData.children.push({ ...child, active: false })
            continue
          }

          routeData.children.push(child)
        }
        filteredRouteGroup.routes.push({ ...routeData })

        continue
      }

      // Default route
      filteredRouteGroup.routes.push(route)
    }

    if (filteredRouteGroup.routes.length > 0) {
      filteredRoutes.push(filteredRouteGroup)
    }
  }
  // }

  return filteredRoutes
}
