export enum ECreateOrganizationStep {
  ORGANIZATION = 'Create Organization',
  ACCOUNT = 'Create Account'
}

export enum EProcessStatus {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  REJECTED = 'Rejected'
}
