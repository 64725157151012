import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const CategoryIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33341 1.51306V4.26676C9.33341 4.64012 9.33341 4.82681 9.40608 4.96942C9.46999 5.09486 9.57198 5.19684 9.69742 5.26076C9.84003 5.33342 10.0267 5.33342 10.4001 5.33342H13.1538M9.33341 11.3334H5.33341M10.6667 8.66671H5.33341M13.3334 6.65886V11.4667C13.3334 12.5868 13.3334 13.1469 13.1154 13.5747C12.9237 13.951 12.6177 14.257 12.2414 14.4487C11.8136 14.6667 11.2535 14.6667 10.1334 14.6667H5.86675C4.74664 14.6667 4.18659 14.6667 3.75877 14.4487C3.38244 14.257 3.07648 13.951 2.88473 13.5747C2.66675 13.1469 2.66675 12.5868 2.66675 11.4667V4.53337C2.66675 3.41327 2.66675 2.85322 2.88473 2.42539C3.07648 2.04907 3.38244 1.74311 3.75877 1.55136C4.18659 1.33337 4.74664 1.33337 5.86675 1.33337H8.00793C8.49711 1.33337 8.7417 1.33337 8.97188 1.38863C9.17595 1.43763 9.37104 1.51844 9.54998 1.62809C9.75182 1.75178 9.92477 1.92473 10.2707 2.27063L12.3962 4.39612C12.7421 4.74202 12.915 4.91497 13.0387 5.1168C13.1484 5.29575 13.2292 5.49084 13.2782 5.69491C13.3334 5.92509 13.3334 6.16968 13.3334 6.65886Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CategoryIcon
