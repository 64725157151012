import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const ContactIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 10.5579C12.9705 11.0455 13.8026 11.828 14.4101 12.8064C14.5303 13.0002 14.5905 13.0971 14.6113 13.2312C14.6535 13.5038 14.4671 13.839 14.2132 13.9469C14.0882 14 13.9477 14 13.6666 14M10.6666 7.68816C11.6544 7.19726 12.3332 6.17791 12.3332 5C12.3332 3.82209 11.6544 2.80274 10.6666 2.31184M9.33322 5C9.33322 6.65685 7.99008 8 6.33322 8C4.67637 8 3.33322 6.65685 3.33322 5C3.33322 3.34315 4.67637 2 6.33322 2C7.99008 2 9.33322 3.34315 9.33322 5ZM1.70604 12.6256C2.76892 11.0297 4.44614 10 6.33322 10C8.22031 10 9.89753 11.0297 10.9604 12.6256C11.1932 12.9752 11.3097 13.15 11.2963 13.3733C11.2858 13.5471 11.1719 13.76 11.0329 13.8651C10.8545 14 10.6091 14 10.1183 14H2.54813C2.05734 14 1.81194 14 1.63352 13.8651C1.49459 13.76 1.38062 13.5471 1.37018 13.3733C1.35678 13.15 1.4732 12.9752 1.70604 12.6256Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ContactIcon
