import DashboardIcon from '../SideBarIcons/DashboardIcon'
import TransactionIcon from '../SideBarIcons/TransactionIcon'
import ContactIcon from '../SideBarIcons/ContactIcon'
import CategoryIcon from '../SideBarIcons/CategoryIcon'
import SourceIcon from '../SideBarIcons/SourceIcon'
import MembersIcon from '../SideBarIcons/MemberIcon'
import NewTransferIcon from '../SideBarIcons/NewTransfer'
import RecievePayment from '../SideBarIcons/RecievePayment'
import SettingIcon from '../SideBarIcons/SettingIcon'
import AssetIcon from '../SideBarIcons/AssetIcon'
import ExternalLinkIcon from '../ActionIcons/ExternalLinkIcon'
import CopyIcon from '../ActionIcons/CopyIcon'
import PendingApprovalIcon from '../SideBarIcons/PendingApprovalIcon'
import IntegrationIcon from '../SideBarIcons/IntegrationIcon'
import NFTIcon from '../SideBarIcons/NFTIcon'
import InvoiceIcon from '../SideBarIcons/InvoiceIcon'
import CardIcon from '../SideBarIcons/CardIcon'

export const IconMap = {
  DashboardIcon,
  TransactionIcon,
  ContactIcon,
  CategoryIcon,
  SourceIcon,
  MembersIcon,
  NewTransferIcon,
  CardIcon,
  RecievePayment,
  SettingIcon,
  AssetIcon,
  ExternalLinkIcon,
  CopyIcon,
  PendingApprovalIcon,
  IntegrationIcon,
  NFTIcon,
  InvoiceIcon
}
