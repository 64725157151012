import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const SourceIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 15,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 14
}) => (
  <svg width={width} height={height} viewBox="0 0 14 15" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66667 5.3342V3.0013C9.66667 2.44681 9.66667 2.16956 9.54986 1.99918C9.44781 1.85032 9.28976 1.74919 9.11184 1.71892C8.90819 1.68426 8.65646 1.80044 8.153 2.03281L2.23934 4.76219C1.79034 4.96942 1.56583 5.07304 1.4014 5.23374C1.25604 5.37581 1.14508 5.54923 1.077 5.74075C1 5.95739 1 6.20465 1 6.69917V10.0009M10 9.66753H10.0067M1 7.46753L1 11.8675C1 12.6143 1 12.9876 1.14532 13.2728C1.27316 13.5237 1.47713 13.7277 1.72801 13.8555C2.01323 14.0009 2.3866 14.0009 3.13333 14.0009H10.8667C11.6134 14.0009 11.9868 14.0009 12.272 13.8555C12.5229 13.7277 12.7268 13.5237 12.8547 13.2728C13 12.9876 13 12.6143 13 11.8675V7.46753C13 6.72079 13 6.34742 12.8547 6.06221C12.7268 5.81133 12.5229 5.60735 12.272 5.47952C11.9868 5.3342 11.6134 5.3342 10.8667 5.3342L3.13333 5.33419C2.3866 5.33419 2.01323 5.3342 1.72801 5.47952C1.47713 5.60735 1.27316 5.81132 1.14532 6.06221C1 6.34742 1 6.72079 1 7.46753ZM10.3333 9.66753C10.3333 9.85162 10.1841 10.0009 10 10.0009C9.81591 10.0009 9.66667 9.85162 9.66667 9.66753C9.66667 9.48343 9.81591 9.33419 10 9.33419C10.1841 9.33419 10.3333 9.48343 10.3333 9.66753Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SourceIcon
