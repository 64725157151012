/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
import NavLink from './NavLink'
import { SideMenuItemCollapsible as NavLinkWithChildren } from '@/components-v2/molecules/SideMenuItemCollapsible'

const NavLinkContainer = ({
  section,
  isSidebarOpen,
  index,
  organizationId,
  currentPage,
  isPendingTransactionsLoading,
  pendingApprovalsTotal = 0
}) => {
  if (section.title) {
    return (
      <div className="mb-3" key={index}>
        {isSidebarOpen && (
          <div
            className="uppercase -mb-0"
            style={{ fontSize: 10, color: '#777675', letterSpacing: '0.1em', fontWeight: 600 }}
          >
            {section.title}
          </div>
        )}
        {section.routes.map((route, _index) => {
          if (route?.children?.length > 0) {
            return (
              <div key={_index}>
                <NavLinkWithChildren
                  id={route.title}
                  organizationId={organizationId}
                  currentPage={currentPage}
                  isSidebarOpen={isSidebarOpen}
                  childPaths={route.children?.map((child) => `/[organizationId]${child.path}`) || []}
                >
                  <NavLinkWithChildren.CTA icon={route.icon} text={route.title} displayCaret={isSidebarOpen} />
                  <NavLinkWithChildren.Menu>
                    {route.children.map((childRoute, _childIndex) => (
                      <NavLinkWithChildren.Item
                        active={childRoute.active}
                        path={childRoute.path}
                        index={_childIndex}
                        key={_childIndex}
                        pendingApprovals={pendingApprovalsTotal}
                        isLoading={isPendingTransactionsLoading}
                      >
                        {childRoute.title}
                      </NavLinkWithChildren.Item>
                    ))}
                  </NavLinkWithChildren.Menu>
                </NavLinkWithChildren>
              </div>
            )
          }

          return (
            <NavLink
              isSidebarOpen={isSidebarOpen}
              organizationId={organizationId}
              item={route}
              nonAdmin={false}
              currentPage={currentPage}
              key={_index}
              pendingApprovals={pendingApprovalsTotal}
              isPendingTransactionsLoading={isPendingTransactionsLoading}
            />
          )
        })}
      </div>
    )
  } else if (length - 1 === index) {
    // Last row
    return (
      <div className="flex flex-col grow justify-end mb-2.5 " key={index}>
        {section.routes.map((route, _index) => (
          <NavLink
            organizationId={organizationId}
            item={route}
            isSidebarOpen={isSidebarOpen}
            nonAdmin={false}
            currentPage={currentPage}
            key={_index}
          />
        ))}
      </div>
    )
  }
  return (
    <div className={index === 0 ? 'mb-3 -mt-2' : ''} key={index}>
      {section.routes.map((route, _index) => (
        <NavLink
          isSidebarOpen={isSidebarOpen}
          organizationId={organizationId}
          item={route}
          nonAdmin={false}
          currentPage={currentPage}
          key={_index}
        />
      ))}
    </div>
  )
}

export default NavLinkContainer
