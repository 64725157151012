import { supportedChainsSelector } from '@/slice/chains/chains-slice'
import { useAppSelector } from '@/state'
import { captureException as sentryCaptureException } from '@sentry/nextjs'
import { useEffect, useState } from 'react'
import { useConnect, useSwitchChain } from 'wagmi'
import useStorage from '../utility/useStorage'

const useConnectWeb3 = () => {
  const { setItem } = useStorage('session')

  const supportedChains = useAppSelector(supportedChainsSelector)

  const [localError, setLocalError] = useState('')

  const { connectAsync, connectors, error } = useConnect()
  const { switchChainAsync } = useSwitchChain()

  useEffect(() => {
    if (error) {
      //       const defaultChain = supportedChains.find((chain) => chain.id === 'ethereum')
      //       switchDisconnectWalletNetwork(parseInt(defaultChain.chainId))
    }
  }, [error])

  const connectMetamaskWallet = async () => {
    setLocalError('')
    if (typeof window.ethereum === 'undefined') {
      window.open('https://metamask.io/download/', '_blank')
      return
    }

    try {
      const injectedConnector = connectors.find((connector) => connector.id === 'injected')
      await connectAsync({ connector: injectedConnector })

      setItem('wallet-provider', 'injected')
    } catch (err) {
      sentryCaptureException(err ?? error)
      console.log('Metamask Connection Error: ', err)
    }
  }

  const connectWalletConnect = async () => {
    setLocalError('')
    try {
      const walletConnectConnector = connectors.find((connector) => connector.id === 'walletConnect')
      await connectAsync({ connector: walletConnectConnector })

      setItem('wallet-provider', 'wallet-connect')
    } catch (err) {
      sentryCaptureException(err)
      console.log('WalletConnect Connection Error: ', err)
    }
  }

  // TODO - Move this to a service in future
  async function switchDisconnectWalletNetwork(chainId = 1) {
    // Check for ethereum object, this covers MetaMask and similar wallets
    if (switchChainAsync) {
      try {
        await switchChainAsync({ chainId: Number(chainId) })
      } catch (_error: any) {
        // TODO - Handle the case where the network has not been added
        if (_error.code === 4902) {
          // Here add logic to add network
          console.error('Network not found', error)
        } else if (_error.code === 4001) {
          // Handle user rejection
          setLocalError('User rejected network switch')
        } else {
          setLocalError('Error switching network')
        }
      }
    } else {
      // Handle non-ethereum object wallets
      setLocalError('Please install Metamask to connect to the application.')
    }
  }

  return { error: localError, connectMetamaskWallet, connectWalletConnect }
}

export default useConnectWeb3
