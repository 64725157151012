/* eslint-disable react-hooks/exhaustive-deps */
import { setChain } from '@/slice/platform/platform-slice'
import { useAppDispatch } from '@/state'
import { useEffect } from 'react'
import { useChainId } from 'wagmi'

export const useWeb3Listener = () => {
  const chainId = useChainId()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (chainId) {
      dispatch(setChain(String(chainId)))
    }
  }, [chainId])
}

export default useWeb3Listener
