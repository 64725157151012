import WalletAddress from '@/components-v2/molecules/WalletAddressCopy/WalletAddress'
import FlagIcon from '@/public/svg/RedFlag.svg'
import { Group, Text } from '@mantine/core'
import Image from 'next/image'

const NameCell = ({ data }) => (
  <div className="w-full">
    <Group gap={6}>
      {data.flag && <Image src={FlagIcon} alt="flag" width={12} height={12} />}
      <Text truncate className="flex-1">
        {data.title}
      </Text>
    </Group>
    <WalletAddress split={5} address={data?.address} variant="caption">
      <WalletAddress.Link address={data?.address} options={data?.chains} />
      <WalletAddress.Copy address={data?.address} />
    </WalletAddress>
  </div>
)

export default NameCell
