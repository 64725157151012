import ChainList from '@/components-v2/molecules/ChainList/ChainList'
import { Text } from '@mantine/core'

const ChainsCell = ({ data, isWalletSyncing, chainsFilter }) => {
  const walletChainMap = data?.chains
    ?.map((item) => ({
      ...item,
      isGrayedOut: chainsFilter?.length > 0 ? !chainsFilter.includes(item.id) : false
    }))
    .sort((chain) => (chain.isGrayedOut ? -1 : 1))

  return isWalletSyncing ? (
    <div className="skeleton skeleton-text mt-1" style={{ width: 120 }} />
  ) : (
    <div>
      <ChainList chains={walletChainMap} />
      <Text c="gray" size="sm">
        {data.type}
      </Text>
    </div>
  )
}
export default ChainsCell
