import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const SettingIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00065 10.0007C9.10522 10.0007 10.0007 9.10522 10.0007 8.00065C10.0007 6.89608 9.10522 6.00065 8.00065 6.00065C6.89608 6.00065 6.00065 6.89608 6.00065 8.00065C6.00065 9.10522 6.89608 10.0007 8.00065 10.0007Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.19337 12.9147L6.583 13.791C6.69883 14.0519 6.88786 14.2735 7.12715 14.4291C7.36645 14.5846 7.64574 14.6674 7.93115 14.6673C8.21656 14.6674 8.49585 14.5846 8.73515 14.4291C8.97445 14.2735 9.16347 14.0519 9.2793 13.791L9.66893 12.9147C9.80763 12.6038 10.0409 12.3446 10.3356 12.174C10.6321 12.0029 10.9752 11.9301 11.3156 11.9658L12.2689 12.0673C12.5527 12.0973 12.8391 12.0444 13.0934 11.9149C13.3477 11.7854 13.5589 11.5849 13.7015 11.3377C13.8443 11.0907 13.9123 10.8075 13.8973 10.5226C13.8824 10.2376 13.785 9.96319 13.6171 9.7325L13.0526 8.95695C12.8516 8.67874 12.7443 8.34386 12.746 8.00065C12.7459 7.65838 12.8543 7.32489 13.0556 7.04806L13.62 6.2725C13.788 6.04182 13.8853 5.76736 13.9003 5.48243C13.9153 5.1975 13.8473 4.91435 13.7045 4.66732C13.5619 4.42014 13.3506 4.21964 13.0964 4.09014C12.8421 3.96063 12.5557 3.90767 12.2719 3.93769L11.3186 4.03917C10.9781 4.07492 10.6351 4.00207 10.3386 3.83102C10.0433 3.65949 9.80994 3.39889 9.67189 3.08658L9.2793 2.21028C9.16347 1.94943 8.97445 1.72779 8.73515 1.57225C8.49585 1.41671 8.21656 1.33394 7.93115 1.33398C7.64574 1.33394 7.36645 1.41671 7.12715 1.57225C6.88786 1.72779 6.69883 1.94943 6.583 2.21028L6.19337 3.08658C6.05533 3.39889 5.82196 3.65949 5.52671 3.83102C5.23018 4.00207 4.88715 4.07492 4.54671 4.03917L3.59041 3.93769C3.30663 3.90767 3.02024 3.96063 2.76595 4.09014C2.51167 4.21964 2.30042 4.42014 2.15782 4.66732C2.01503 4.91435 1.94701 5.1975 1.962 5.48243C1.97699 5.76736 2.07435 6.04182 2.24226 6.2725L2.80671 7.04806C3.008 7.32489 3.1164 7.65838 3.11634 8.00065C3.1164 8.34293 3.008 8.67642 2.80671 8.95324L2.24226 9.7288C2.07435 9.95948 1.97699 10.2339 1.962 10.5189C1.94701 10.8038 2.01503 11.087 2.15782 11.334C2.30056 11.581 2.51184 11.7814 2.76608 11.9109C3.02033 12.0404 3.30665 12.0934 3.59041 12.0636L4.54374 11.9621C4.88419 11.9264 5.22722 11.9992 5.52375 12.1703C5.8201 12.3413 6.05455 12.602 6.19337 12.9147Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SettingIcon
