import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const PendingApprovalIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 4.00016V8.00016L10.6663 9.3335M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PendingApprovalIcon
