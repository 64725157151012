import { IChainItem } from '@/api/chain-api'
import AllChainsIcon from '@/public/svg/allChains.svg'
import { supportedChainsSelector } from '@/slice/chains/chains-slice'
import { useAppSelector } from '@/state'
import { Button, Flex, Popover } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import _, { chain } from 'lodash'
import Image from 'next/image'
import { useMemo } from 'react'

interface ChainFiltersProps {
  values: string[]
  onChange: (value: string) => void
  onClear: () => void
  chainOrder?: string[]
  removeChains?: string[]
}

interface ChainItemProps extends IChainItem {
  onChange: (value: string) => void
  active: boolean
}

const DEFAULT_NUMBER_OF_ITEMS = 4

const ChainItem = ({ imageUrl, name, id, active, onChange }: ChainItemProps) => (
  <Button
    size="compact-md"
    c={active ? 'dark' : 'gray.7'}
    color={active ? '#E2E2E0' : 'gray.0'}
    fw={active ? 600 : 400}
    leftSection={<Image src={imageUrl} height={16} width={16} alt="icon" className="rounded-sm" />}
    onClick={(e) => onChange(id)}
  >
    {name}
  </Button>
)

const ChainFilters: React.FC<ChainFiltersProps> = ({
  onChange,
  onClear,
  values = [],
  chainOrder = [],
  removeChains = []
}) => {
  const supportedChains: IChainItem[] = useAppSelector(supportedChainsSelector) || []

  const chainsOptions: { shownItems: IChainItem[]; dropdownItems: IChainItem[] } = useMemo(() => {
    // remove
    const removedChains = supportedChains.filter((item) => !removeChains.includes(item.id))

    // re-order
    const sortedChains = _.sortBy(removedChains, (item) => {
      const index = chainOrder.indexOf(item.id)
      return index === -1 ? chainOrder.length : index
    })

    const isHasDropdown = sortedChains.length - DEFAULT_NUMBER_OF_ITEMS >= 1
    const shownItems = isHasDropdown ? sortedChains.slice(0, DEFAULT_NUMBER_OF_ITEMS - 1) : sortedChains
    const dropdownItems = sortedChains.slice(DEFAULT_NUMBER_OF_ITEMS - 1) || []

    return { shownItems, dropdownItems }
  }, [supportedChains, removeChains, chainOrder])

  const isAllChainsSelected = !values.length
  const isDropdownChainsSelected =
    values.length &&
    chainsOptions.dropdownItems.length &&
    chainsOptions.dropdownItems.some((item) => values.includes(item.id))

  return (
    <Flex gap="sm">
      <Button
        size="compact-md"
        c={isAllChainsSelected ? 'dark' : 'gray.7'}
        color={isAllChainsSelected ? '#E2E2E0' : 'gray.0'}
        fw={isAllChainsSelected ? 600 : 400}
        leftSection={<Image src={AllChainsIcon} height={16} width={16} alt="icon" />}
        onClick={onClear}
      >
        All Chains
      </Button>

      {chainsOptions.shownItems.map((item) => {
        const active = values.includes(item.id)
        return <ChainItem {...item} onChange={onChange} active={active} />
      })}

      {chainsOptions.dropdownItems.length > 0 && (
        <Popover width={280} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Button
              size="compact-md"
              c={isDropdownChainsSelected ? 'dark' : 'gray.7'}
              color={isDropdownChainsSelected ? '#E2E2E0' : 'gray.0'}
              fw={isDropdownChainsSelected ? 600 : 400}
              rightSection={<IconChevronDown size={16} />}
              className="[&[aria-expanded=true]_svg]:rotate-180 [&_svg]:transition-transform"
            >
              +{chainsOptions.dropdownItems.length}
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Flex wrap="wrap" gap="xs">
              {chainsOptions.dropdownItems.map((item) => {
                const active = values.includes(item.id)
                return <ChainItem {...item} onChange={onChange} active={active} />
              })}
            </Flex>
          </Popover.Dropdown>
        </Popover>
      )}
    </Flex>
  )
}

export default ChainFilters
