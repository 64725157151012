/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-else-return */
import { useState, FC, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { resetWallet, setFakeLoader } from '@/slice/wallets/wallet-slice'
import { useRouter } from 'next/router'
import OrgSwitchDropdown from '@/components-v2/molecules/Sidebar-v2/OrgSwitchDropdown'
import Caret from '@/public/svg/icons/caret-icon-black.svg'
import { EProcessStatus } from '@/views/Organization/interface'
import { useOrganizationId } from '@/utils/getOrganizationId'
import NotificationPopUp from '@/components/NotificationPopUp/NotificationPopUp'
import { useLazyConnectOrgQuery } from '@/slice/organization/organization.api'
import Image from 'next/legacy/image'
import { isMonetisationEnabled } from '@/config/constants'
import { SubscriptionStatus, useLazyGetSubscriptionQuery } from '@/api/subscription-api'
import { useGetPendingTransactionsNewQuery } from '@/slice/pending-transactions/pending-transactions.api'
import NavLinkContainer from './components/NavLinkContainer'

import { TXN_COLUMNS_STORAGE_KEY, TXN_FILTERS_STORAGE_KEY } from '@/views/Transactions/interface'
import CreateOrganizationModal from '@/components-v2/molecules/CreateOrganizationModal-v2'
import { ISideBarProps } from './index'
import { getRoutes } from './siderbar.services'

const SideNavBar: FC<ISideBarProps> = ({ orgList, currentOrg, user, bannerDisplayed = false, plan }) => {
  const [isOpen, setIsOpen] = useState(false)

  const router = useRouter()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const orgId = useOrganizationId()
  const dispatch = useDispatch()
  const [error, setError] = useState<string>()
  const [status, setStatus] = useState<EProcessStatus>(EProcessStatus.PENDING)
  const currentPage = router.route
  const [isCreateOrgOpen, setIsCreateOrgOpen] = useState(false)
  const [showError, setShowError] = useState(false)
  const [triggerConnectOrganization] = useLazyConnectOrgQuery()
  const [triggerGetSubscription, getSubscriptionResponse] = useLazyGetSubscriptionQuery()
  const selectedOrgId = useRef<string>(null)

  const {
    data: pendingApprovals,
    isLoading: isPendingTransactionsLoading,
    isFetching: isPendingTransactionsFetching
  } = useGetPendingTransactionsNewQuery(
    {
      organizationId: orgId,
      params: {
        blockchainIds: [],
        walletIds: []
      }
    },
    { skip: !orgId }
  )

  useEffect(() => {
    if (status === EProcessStatus.FAILED) {
      setIsCreateOrgOpen(false)
      setShowError(true)
    }
  }, [status])

  useEffect(() => {
    if (getSubscriptionResponse.data && !getSubscriptionResponse.isLoading) {
      if (getSubscriptionResponse.data?.status === SubscriptionStatus.EXPIRED) {
        router.push(`/${selectedOrgId.current}/orgsettings?activeTab=pricingAndPlans`)
      } else {
        triggerConnectOrganization({ organisationId: selectedOrgId.current })
        router.push(`/${selectedOrgId.current}/dashboard`)
      }
    }
  }, [getSubscriptionResponse.data, getSubscriptionResponse.isLoading])

  const handleOnClickDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleCreateOrg = () => {
    setIsOpen(false)
    setIsCreateOrgOpen(true)
  }
  const handleChangeOrg = (_org) => {
    dispatch(resetWallet())
    dispatch(setFakeLoader(true))
    window.sessionStorage.removeItem(TXN_FILTERS_STORAGE_KEY)
    window.sessionStorage.removeItem(TXN_COLUMNS_STORAGE_KEY)
    setIsOpen(false)
    selectedOrgId.current = _org.id
    triggerGetSubscription({ organizationId: _org.id })
  }
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <>
      {/* {getSubscriptionResponse.isLoading || (getSubscriptionResponse?.isFetching && <LoadingOverlay />)} */}
      <div
        className={`pl-[16px] font-inter flex flex-col transition-all duration-250
      ease-out ${isSidebarOpen ? 'w-[240px]' : 'w-[80px]'} `}
      >
        <div className={`relative ${bannerDisplayed ? 'h-[calc(100vh-152px)]' : 'h-[calc(100vh-84px)]'}`}>
          <div
            className={`overflow-y-auto invisible-scrollbar flex flex-col justify-between mb-4 ${
              // isMonetisationEnabled && plan?.planName === PlanName.FREE_TRIAL
              //   ? bannerDisplayed
              //     ? 'h-[calc(100vh-280px)]'
              //     : 'h-[calc(100vh-212px)]'
              // :
              bannerDisplayed ? 'h-[calc(100vh-152px)]' : 'h-[calc(100vh-84px)]'
            }`}
          >
            <div>
              <OrgSwitchDropdown
                onClickCreateOrg={handleCreateOrg}
                isOpen={isOpen}
                isSidebarOpen={isSidebarOpen}
                onClick={handleOnClickDropdown}
                orgList={orgList}
                currentOrg={currentOrg}
                handleChangeOrg={handleChangeOrg}
                role={user?.role}
                disableProfileOption={isMonetisationEnabled && plan?.status === SubscriptionStatus.EXPIRED}
              />
              <div className="flex flex-col grow pt-6 w-full">
                {getRoutes({
                  planStatus: plan?.status,
                  isMobile: false,
                  organizationId: orgId
                }).map((section, index) => (
                  <NavLinkContainer
                    section={section}
                    index={index}
                    currentPage={currentPage}
                    isSidebarOpen={isSidebarOpen}
                    organizationId={orgId}
                    key={index}
                    isPendingTransactionsLoading={isPendingTransactionsFetching || isPendingTransactionsLoading}
                    pendingApprovalsTotal={pendingApprovals?.data?.length ?? 0}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* {isMonetisationEnabled && plan?.planName === 'free_trial' && (
          <PlanStatusBox expiredAt={plan?.expiredAt} status={plan?.status} />
        )} */}
          <div
            onClick={handleToggleSidebar}
            className="absolute top-1/2 -right-6 bg-[#CECECC] rounded h-[28px] w-[16px] hover:cursor-pointer flex justify-center items-center "
          >
            <Image
              className={`transition-transform duration-500 transform ${isSidebarOpen ? 'rotate-180' : ''}`}
              src={Caret}
              alt="caret"
              height={12}
              width={12}
            />
          </div>
        </div>
        {isCreateOrgOpen && (
          <CreateOrganizationModal
            setShowModal={setIsCreateOrgOpen}
            showModal={isCreateOrgOpen}
            setError={setError}
            setStatus={setStatus}
            status={status}
          />
        )}
        {error && (
          <NotificationPopUp
            acceptText="Dismiss"
            title="Organization Already Exists"
            description={error}
            type="error"
            setShowModal={setShowError}
            showModal={showError}
            onClose={() => {
              setError(undefined)
              setStatus(EProcessStatus.PENDING)
            }}
          />
        )}
      </div>
    </>
  )
}

export default SideNavBar
