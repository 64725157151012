import { IntegrationName } from '@/api/organization-integrations'
import { AppState } from '@/state'
import { createSelector } from '@reduxjs/toolkit'
import { OrgIntegrationStatus, OrgIntegration } from './org-integration-slice'

const selectSelf = (state: AppState) => state.orgIntegration

export const accountingIntegrationSelector = createSelector(selectSelf, (state: AppState) =>
  state.organizationIntegrations?.find(
    (integration) =>
      [IntegrationName.XERO, IntegrationName.QUICKBOOKS].includes(integration.integrationName) &&
      [
        OrgIntegrationStatus.COMPLETED,
        OrgIntegrationStatus.TOKEN_SWAPPED,
        OrgIntegrationStatus.AUTHENTICATION_NEEDED
      ].includes(integration.status)
  )
)

export const rootfiIntegrationSelector = createSelector(selectSelf, (state: AppState) =>
  state.organizationIntegrations?.find(
    (integration) =>
      [IntegrationName.XERO, IntegrationName.QUICKBOOKS].includes(integration.integrationName) &&
      ![OrgIntegrationStatus.INITIATED, OrgIntegrationStatus.DISCONNECTED_STANDBY].includes(integration.status) &&
      integration.platform === 'rootfi'
  )
)

export const integrationSelector = createSelector(selectSelf, (state: AppState) => state.organizationIntegrations)

export const selectOrganisationIntegrationsMap = createSelector(selectSelf, (state: AppState) => {
  const integrationMap = new Map<string, OrgIntegration>()
  state.organizationIntegrations?.forEach((integration) => {
    integrationMap.set(integration.integrationName, integration)
  })
  return integrationMap
})
