import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const AssetIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33333 4.99936V10.3327M5.33333 4.99936V10.3327M8.66667 4.99936V10.3327M11.6667 4.99936V10.3327M1 11.3994L1 11.9327C1 12.3061 1 12.4927 1.07266 12.6354C1.13658 12.7608 1.23856 12.8628 1.36401 12.9267C1.50661 12.9994 1.6933 12.9994 2.06667 12.9994H11.9333C12.3067 12.9994 12.4934 12.9994 12.636 12.9267C12.7614 12.8628 12.8634 12.7608 12.9273 12.6354C13 12.4927 13 12.3061 13 11.9327V11.3994C13 11.026 13 10.8393 12.9273 10.6967C12.8634 10.5713 12.7614 10.4693 12.636 10.4054C12.4934 10.3327 12.3067 10.3327 11.9333 10.3327H2.06667C1.6933 10.3327 1.50661 10.3327 1.36401 10.4054C1.23857 10.4693 1.13658 10.5713 1.07266 10.6967C1 10.8393 1 11.026 1 11.3994ZM6.76861 1.05078L1.83527 2.14708C1.53723 2.21331 1.38821 2.24643 1.27698 2.32656C1.17886 2.39725 1.10182 2.49329 1.0541 2.60441C1 2.73038 1 2.88303 1 3.18835L1 3.93269C1 4.30606 1 4.49275 1.07266 4.63536C1.13658 4.7608 1.23856 4.86278 1.36401 4.9267C1.50661 4.99936 1.6933 4.99936 2.06667 4.99936H11.9333C12.3067 4.99936 12.4934 4.99936 12.636 4.9267C12.7614 4.86278 12.8634 4.7608 12.9273 4.63536C13 4.49275 13 4.30606 13 3.9327V3.18835C13 2.88303 13 2.73038 12.9459 2.60441C12.8982 2.49329 12.8211 2.39725 12.723 2.32656C12.6118 2.24643 12.4628 2.21331 12.1647 2.14708L7.23139 1.05078C7.14504 1.03159 7.10187 1.022 7.05824 1.01817C7.01949 1.01478 6.98051 1.01478 6.94176 1.01817C6.89813 1.022 6.85496 1.03159 6.76861 1.05078Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AssetIcon
