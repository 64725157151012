import { createSlice } from '@reduxjs/toolkit'
import { IOrganization } from './organization.types'
import { accountApi } from '@/api/account-api'

interface IOrganizationSliceState {
  data: any
  userOrganizations: IOrganization[] | []
}

const initialState: IOrganizationSliceState = {
  data: null,
  userOrganizations: []
}

export const organizationSlice = createSlice({
  name: 'organization-slice',
  initialState,
  reducers: undefined,
  extraReducers: (builder) => {
    builder.addMatcher(accountApi.endpoints.getUserOrgAccount.matchFulfilled, (state, { payload }) => {
      state.userOrganizations = payload?.data
    })
  }
})
