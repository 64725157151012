import { ActionIcon, Menu } from '@mantine/core'
import { IconDotsVertical } from '@tabler/icons-react'
import ReactTooltip from 'react-tooltip'

const ActionCell = ({ data, isWalletSyncing }) => (
  <Menu shadow="md" width={120} position="bottom-end">
    <Menu.Target>
      <ActionIcon
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <IconDotsVertical size={20} />
      </ActionIcon>
    </Menu.Target>

    <Menu.Dropdown>
      <Menu.Item component="button" onClick={data.onEditButton}>
        Edit
      </Menu.Item>
      <Menu.Item
        data-tip="deleteWallet"
        data-for="deleteWallet"
        component="button"
        disabled={isWalletSyncing}
        className="disabled:cursor-not-allowed !pointer-events-auto"
        onClick={data.onButtonClick}
        c="red"
      >
        Delete
      </Menu.Item>
      {isWalletSyncing && (
        <ReactTooltip
          id="deleteWallet"
          place="top"
          borderColor="#eaeaec"
          border
          backgroundColor="white"
          textColor="#111111"
          effect="solid"
          className="!opacity-100 !rounded-lg !text-xs max-w-[244px]"
        >
          We are syncing transactions data. You will be able to delete a wallet after the sync is completed.
        </ReactTooltip>
      )}
    </Menu.Dropdown>
  </Menu>
)

export default ActionCell
