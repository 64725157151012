import { useDeleteWalletGroupMutation } from '@/api/wallet-group-api'
import NotificationPopUp from '@/components/NotificationPopUp/NotificationPopUp'
import { showBannerSelector } from '@/slice/platform/platform-slice'
import { useAppSelector } from '@/state'
import { log } from '@/utils-v2/logger'
import { useOrganizationId } from '@/utils/getOrganizationId'
import React, { useEffect, useState } from 'react'
import CreateGroupModal from '../CreateGroupModal/CreateGroupModal'
import WalletGroupTable from './WalletGroupTable'
import { Input } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import ChainFilters from '@/components-v2/molecules/ChainFilters/ChainFilters'

interface IWalletGroup {
  data: any
  loading: boolean
  supportedChains: any[]
  groupChainsFilter: string[]
  areAllChainsSelected: boolean
  onChangeFilter: (chain) => void
  onSelectAllChain: () => void
  search: string
  onChangeSearch: (search) => void
}

const WalletGroup: React.FC<IWalletGroup> = ({
  data,
  loading,
  supportedChains,
  groupChainsFilter,
  areAllChainsSelected,
  onChangeFilter,
  onSelectAllChain,
  search,
  onChangeSearch
}) => {
  const [deleteWalletGroup, deleteWalletGroupResult] = useDeleteWalletGroupMutation()

  const organizationId = useOrganizationId()
  const showBanner = useAppSelector(showBannerSelector)

  const [selectedGroup, setSelectedGroup] = useState<any>()
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!showEdit) {
      setSelectedGroup(null)
    }
  }, [showEdit])

  useEffect(() => {
    if (!showDelete) {
      setSelectedGroup(null)
    }
  }, [showDelete])

  useEffect(() => {
    if (deleteWalletGroupResult.isSuccess) {
      setShowSuccessModal(true)
      setShowDelete(false)
    }
    if (deleteWalletGroupResult.isError) {
      setShowErrorModal(true)
      setErrorMessage(deleteWalletGroupResult?.error?.data?.message)
      setShowDelete(false)
      log.error(
        `${deleteWalletGroupResult?.error?.status} API Error while deleting wallet group`,
        [`${deleteWalletGroupResult?.error?.status} API Error while deleting wallet group`],
        {
          actualErrorObject: deleteWalletGroupResult?.error
        },
        `${window.location.pathname}`
      )
    }
  }, [deleteWalletGroupResult])

  const handleDelete = () => {
    if (selectedGroup) {
      deleteWalletGroup({
        payload: {
          id: selectedGroup.id
        },
        orgId: organizationId
      })
    }
  }
  const onOpenEditModal = (row) => {
    setSelectedGroup(row)
    setShowEdit(true)
  }
  const onOpenDeleteModal = (row) => {
    setSelectedGroup(row)
    setShowDelete(true)
  }

  return (
    <div className="pt-6">
      <div className="flex items-center justify-center mb-3">
        <ChainFilters values={groupChainsFilter} onChange={onChangeFilter} onClear={onSelectAllChain} />
      </div>
      <Input
        w="20%"
        miw={250}
        value={search}
        leftSection={<IconSearch size={14} />}
        placeholder="Search by wallet group name"
        onChange={onChangeSearch}
      />
      <WalletGroupTable
        data={data}
        showBanner={showBanner}
        supportedChains={supportedChains}
        groupChainsFilter={groupChainsFilter}
        onEdit={onOpenEditModal}
        onDelete={onOpenDeleteModal}
      />

      {showDelete && (
        <NotificationPopUp
          title="Delete Wallet Group?"
          description={`You are deleting Group ${selectedGroup && selectedGroup.name}. This action cannot be undone.`}
          type="custom"
          image="/svg/warningBig.svg"
          option
          setShowModal={setShowDelete}
          showModal={showDelete}
          declineText="No, Don’t Delete"
          acceptText="Yes, Delete"
          onClose={() => setShowDelete(false)}
          onAccept={handleDelete}
        />
      )}
      {showSuccessModal && (
        <NotificationPopUp
          title="Successfully delete wallet group"
          type="success"
          setShowModal={setShowSuccessModal}
          showModal={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false)
          }}
        />
      )}

      <NotificationPopUp
        acceptText="Dismiss"
        title="Unable to Delete Wallet Group"
        description={errorMessage}
        type="error"
        setShowModal={setShowErrorModal}
        showModal={showErrorModal}
        onClose={() => {
          setShowErrorModal(false)
        }}
      />

      <CreateGroupModal
        groups={data}
        setShowModal={setShowEdit}
        showModal={showEdit}
        selectedGroup={selectedGroup}
        action="Edit"
      />
    </div>
  )
}

export default WalletGroup
