import { createConfig, http } from 'wagmi'
import { arbitrum, base, bsc, gnosis, mainnet, mantle, optimism, polygon, polygonAmoy, sepolia } from 'wagmi/chains'
import { injected, walletConnect } from 'wagmi/connectors'

const projectId = '3612a65e438ee547fbcdcb70f09f61fb'

export const wagmiConfig = createConfig({
  chains: [mainnet, sepolia, optimism, mantle, polygon, polygonAmoy, bsc, base, arbitrum, gnosis],
  connectors: [injected(), walletConnect({ projectId })],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [optimism.id]: http(),
    [polygon.id]: http(),
    [polygonAmoy.id]: http(),
    [mantle.id]: http(),
    [bsc.id]: http(),
    [arbitrum.id]: http(),
    [base.id]: http(),
    [gnosis.id]: http()
  }
})
