/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import NewFilterDropDown from '@/components/DropDown/NewFilterDropDown'
import EmailIcon from '@/public/svg/icons/email-icon-v2.svg'
import TeleGramIcon from '@/public/svg/icons/telegram-icon.svg'
import WhatsAppIcon from '@/public/svg/icons/whatsapp-icon.svg'
import { ActionIcon, Flex, Input } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import Image from 'next/legacy/image'
import { FC, useMemo } from 'react'

interface IContactDropdownProps {
  watch: any
  index: any
  setValue: any
  trigger: any
  onClickRemoveContact: any
  removeable?: boolean
  setError: any
  clearErrors: any
}

const CONTACT_PROVIDERS = {
  Telegram: {
    label: 'Telegram',
    provider: 'Telegram',
    logo: TeleGramIcon,
    placeholder: 'Enter Telegram username'
  },
  WhatsApp: {
    label: 'WhatsApp',
    provider: 'WhatsApp',
    logo: WhatsAppIcon,
    placeholder: 'Enter WhatsApp number with dialling code e.g. +65123'
  },
  Email: {
    label: 'Email',
    provider: 'Email',
    logo: EmailIcon,
    placeholder: 'Enter Email address'
  },
  Other: {
    label: 'Other',
    provider: 'Other',
    placeholder: 'Enter Contact'
  }
}

const parseProviders = () => {
  const providers = []
  for (const [key, value] of Object.entries(CONTACT_PROVIDERS)) {
    providers.push({ value: value.provider, label: value.label })
  }
  return providers
}

const ContactDropdown: FC<IContactDropdownProps> = ({
  watch,
  index,
  removeable,
  setValue,
  trigger,
  onClickRemoveContact
}) => {
  const provider = watch(`contacts[${index}].provider`)
  const content = watch(`contacts[${index}].content`)

  const handleOnChangeProvider = (item) => () => {
    setValue(`contacts[${index}].provider`, item.value)
    setValue(`contacts[${index}].content`, '')
    trigger('contacts')
  }

  const handleOnChange = (e) => {
    setValue(`contacts[${index}].content`, e.target.value.toLowerCase())
    trigger('contacts')
  }

  const handleOnRemoveContact = () => {
    onClickRemoveContact(index)
  }

  const providers = useMemo(() => parseProviders(), [])

  return (
    <Flex align="center" className="gap-3 md:gap-1.5">
      <Flex align="center" bd="1px solid gray.4" className="gap-2 md:gap-0 border rounded-md p-0.5 w-full">
        <NewFilterDropDown
          triggerButton={
            <div
              style={{ borderRadius: 5 }}
              className="md:w-[120px] w-[160px] bg-[#FBFAFA] rounded capitalize text-left p-3 py-[10px] md:p-2 md:py-1.5 flex justify-between items-center text-grey-800 text-sm md:text-xs font-normal"
            >
              <Flex gap={8}>
                {CONTACT_PROVIDERS[provider]?.logo && <Image src={CONTACT_PROVIDERS[provider].logo} alt="icon" />}
                <p>{CONTACT_PROVIDERS[provider].label ?? 'Select'}</p>
              </Flex>
              <img src="/svg/Dropdown.svg" alt="DownArrow" className="w-3 h-auto" />
            </div>
          }
        >
          {providers.map((item, _index) => (
            <button
              type="button"
              key={_index}
              onClick={handleOnChangeProvider(item)}
              className="text-grey-800 flex gap-2 items-center bg-white font-medium w-full py-2 px-4 md:p-2 md:py-1.5 capitalize text-sm text-left hover:bg-gray-50 font-inter"
            >
              {CONTACT_PROVIDERS[item.value]?.logo && <Image src={CONTACT_PROVIDERS[item.value].logo} alt="icon" />}
              <div>{item.label}</div>
            </button>
          ))}
        </NewFilterDropDown>
        <Input
          ml={4}
          flex={1}
          variant="unstyled"
          placeholder={CONTACT_PROVIDERS[provider].placeholder}
          value={content}
          onChange={handleOnChange}
        />
      </Flex>
      {removeable && (
        <ActionIcon variant="transparent" size="sm" onClick={handleOnRemoveContact}>
          <IconX size={14} />
        </ActionIcon>
      )}
    </Flex>
  )
}

export default ContactDropdown
