/* eslint-disable react/no-unstable-nested-components */
import ChainList from '@/components-v2/molecules/ChainList/ChainList'
import { EmptyData } from '@/components-v2/molecules/EmptyData'
import { MantineTable } from '@/components-v2/molecules/MantineTable'
import MoneyIcon from '@/public/svg/MoneyCircle.svg'
import { Button } from '@mantine/core'
import { MRT_ColumnDef as ColumnDef } from 'mantine-react-table'
import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

type WalletGroup = {
  id: string
  name: string
  supportedBlockchains: string[]
  walletsSize: number
}

interface WalletGroupTableProps {
  data: WalletGroup[]
  showBanner: boolean
  supportedChains: any[]
  groupChainsFilter: string[]
  onDelete: (row: WalletGroup) => void
  onEdit: (row: WalletGroup) => void
}

const WalletGroupTable: React.FC<WalletGroupTableProps> = ({
  data,
  groupChainsFilter,
  supportedChains,
  showBanner,
  onDelete,
  onEdit
}) => {
  const columns = useMemo<ColumnDef<WalletGroup>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'walletsSize',
        header: '# Wallet'
      },
      {
        accessorKey: 'supportedBlockchains',
        header: 'Chains',
        Cell: ({ row }) => (
          <ChainsCell row={row.original} supportedChains={supportedChains} groupChainsFilter={groupChainsFilter} />
        )
      },
      {
        id: 'action',
        header: 'Action',
        Cell: ({ row }) => <ActionCell row={row.original} onDelete={onDelete} onEdit={onEdit} />
      }
    ],
    [supportedChains, groupChainsFilter]
  )

  return (
    <MantineTable
      data={data}
      columns={columns}
      enablePagination={false}
      enableBottomToolbar={false}
      mantinePaperProps={{
        mt: 16
      }}
      mantineTableContainerProps={{
        className: `${showBanner ? 'h-[calc(100vh-425px)]' : 'h-[calc(100vh-357px)]'} overflow-auto`
      }}
      renderEmptyRowsFallback={(props) => (
        <EmptyData>
          <EmptyData.Icon icon={MoneyIcon} />
          <EmptyData.Title>No wallet groups found</EmptyData.Title>
        </EmptyData>
      )}
    />
  )
}

export default WalletGroupTable

const ChainsCell = ({ row, supportedChains, groupChainsFilter }) => {
  const groupChainMap = supportedChains
    .filter((supportedChain) => row?.supportedBlockchains.includes(supportedChain.id))
    .map((chain) => ({
      ...chain,
      isGrayedOut: groupChainsFilter?.length > 0 ? !groupChainsFilter.includes(chain.id) : false
    }))

  return <ChainList chains={groupChainMap} />
}

const ActionCell = ({ onDelete, onEdit, row }) => (
  <div className="flex items-center gap-3">
    <Button
      onClick={(e) => {
        e.stopPropagation()
        onEdit(row)
      }}
      variant="default"
      size="xs"
      fz={12}
      fw={600}
    >
      Edit
    </Button>
    <Button
      data-tip={`delete-group-${row.id}`}
      data-for={`delete-group-${row.id}`}
      onClick={(e) => {
        e.stopPropagation()
        onDelete(row)
      }}
      disabled={row?.walletsSize > 0}
      variant="default"
      c="red"
      size="xs"
      fz={12}
      fw={600}
    >
      Delete
    </Button>
    {row?.walletsSize > 0 && (
      <ReactTooltip
        id={`delete-group-${row.id}`}
        borderColor="#eaeaec"
        border
        backgroundColor="white"
        textColor="#111111"
        effect="solid"
        place="top"
        className="!opacity-100 !rounded-lg !text-xs max-w-[240px]"
      >
        Please move out all the wallets from this group before deleting.
      </ReactTooltip>
    )}
  </div>
)
