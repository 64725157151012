/* eslint-disable react/no-array-index-key */
import { FC } from 'react'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { useDisclosure } from '@mantine/hooks'
import { Stack, Box, Flex, Text, Avatar, Button } from '@mantine/core'
import { ISideBarProps } from '../SidebarContainer'
import { IconLogout } from '@tabler/icons-react'
import { toShort } from '@/utils/toShort'
import OrgSwitchDropdown from '@/components-v2/molecules/Sidebar-v2/OrgSwitchDropdown'
import { SubscriptionStatus } from '@/api/subscription-api'
import { getRoutes } from '../siderbar.services'
import NavLinkContainer from './NavLinkContainer'

// TODO - CHange Org Dropdown into an organism so it can access state and we can pass less props

interface IMobileMenuProps {
  onClickCreateOrg: () => void
  onClickSelectOrg: (id: string) => void
  onClickLogout: () => void
  currentPage: string
}

const MobileMenu: FC<ISideBarProps & IMobileMenuProps> = ({
  orgList,
  currentOrg,
  user,
  plan,
  currentPage,
  onClickLogout,
  onClickCreateOrg,
  onClickSelectOrg
}) => {
  const organizationId = useOrganizationId()
  const [isOpen, handler] = useDisclosure(false)

  const handleCreateOrg = () => {
    onClickCreateOrg()
    handler.close()
  }

  const handleSelectOrg = (_value) => {
    handler.toggle()
  }

  const handleChangeOrg = (_value) => {
    console.log('SELECT ORG:', _value)
    onClickSelectOrg(_value?.id)
    handler.toggle()
  }
  return (
    <Box className="h-full flex flex-col ">
      <Box
        className="h-[85px] "
        style={{
          background: 'linear-gradient(to right, rgba(254, 249, 166, 1), rgba(255, 253, 227, 1))'
        }}
      >
        <Flex align="center" p={24} gap={16}>
          <Avatar name={`${user?.firstName} ${user?.lastName}`} size={40} color="initials" />
          <Box>
            <Text size="lg" fw={500}>
              {user?.firstName} {user?.lastName}
            </Text>
            <Text fw={500}>
              {user?.loginCredentials?.length > 20 ? toShort(user?.loginCredentials, 6, 6) : user?.loginCredentials}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Stack p={16} pt={24} className="grow">
        <OrgSwitchDropdown
          onClickCreateOrg={handleCreateOrg}
          isOpen={isOpen}
          isSidebarOpen
          onClick={handleSelectOrg}
          orgList={orgList}
          currentOrg={currentOrg}
          handleChangeOrg={handleChangeOrg}
          role={user?.role}
          disableProfileOption={plan?.status === SubscriptionStatus.EXPIRED}
        />
        <Box className="flex flex-col justify-start  pt-[16px] w-full">
          {getRoutes({
            planStatus: plan?.status,
            isMobile: true,
            organizationId
          }).map((section, index) => (
            <NavLinkContainer
              section={section}
              index={index}
              currentPage={currentPage}
              isSidebarOpen
              organizationId={organizationId}
              key={index}
              isPendingTransactionsLoading={false}
              pendingApprovalsTotal={0}
            />
          ))}
        </Box>
      </Stack>
      <Box className=" p-4">
        <Button
          onClick={onClickLogout}
          variant="transparent"
          styles={{ label: { color: '#C61616', fontSize: 18 } }}
          leftSection={<IconLogout color="#C61616" />}
          size="lg"
        >
          Logout
        </Button>
      </Box>
    </Box>
  )
}

export default MobileMenu
