import { createSlice } from '@reduxjs/toolkit'
import { organizationMembersApi } from './organization-members.api'
import { IOrganizationMember } from './organization-members.types'

interface IOrganizationMembersSliceState {
  members: IOrganizationMember[] | []
}

const initialState: IOrganizationMembersSliceState = {
  members: []
}

export const organizationMembersSlice = createSlice({
  name: 'organization-members-slice',
  initialState,
  reducers: undefined,
  extraReducers: (builder) => {
    builder.addMatcher(organizationMembersApi.endpoints.getMembers.matchFulfilled, (state, { payload }) => {
      state.members = payload?.data?.items
    })
  }
})
