import * as React from 'react'
import { ISvgComponentProps } from '../SVGIcon'

const TransactionIcon: React.FC<Partial<ISvgComponentProps>> = ({
  fill = 'none',
  height = 16,
  stroke = '#777675',
  strokeWidth = 1.5,
  width = 16
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6663 6.66667H1.33301M7.33301 12.6667L12.533 12.6667C13.2797 12.6667 13.6531 12.6667 13.9383 12.5213C14.1892 12.3935 14.3932 12.1895 14.521 11.9387C14.6663 11.6534 14.6663 11.2801 14.6663 10.5333V5.46667C14.6663 4.71993 14.6663 4.34656 14.521 4.06135C14.3932 3.81046 14.1892 3.60649 13.9383 3.47866C13.6531 3.33333 13.2797 3.33333 12.533 3.33333H11.333M7.33301 12.6667L8.66634 14M7.33301 12.6667L8.66634 11.3333M4.66634 12.6667H3.46634C2.7196 12.6667 2.34624 12.6667 2.06102 12.5213C1.81014 12.3935 1.60616 12.1895 1.47833 11.9387C1.33301 11.6534 1.33301 11.2801 1.33301 10.5333V5.46667C1.33301 4.71993 1.33301 4.34656 1.47833 4.06135C1.60616 3.81046 1.81014 3.60649 2.06102 3.47866C2.34624 3.33333 2.7196 3.33333 3.46634 3.33333H8.66634M8.66634 3.33333L7.33301 4.66667M8.66634 3.33333L7.33301 2"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TransactionIcon
