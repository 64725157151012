import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { Web3Provider } from '@ethersproject/providers'
import { Store } from '@reduxjs/toolkit'
import { MantineProvider } from '@mantine/core'
import { mantineTheme } from './shared/theme/mantine.theme'
import { Notifications } from '@mantine/notifications'
import { WagmiProvider } from 'wagmi'
import { wagmiConfig } from './shared/wagmi/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const getLibrary = (provider): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 1000 // Recommended interval in docs for account / network changes
  return library
}

const queryClient = new QueryClient()

const Providers: React.FC<{ children; store: Store }> = ({ children, store }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MantineProvider theme={mantineTheme}>
            <Notifications position="top-right" />
            {children}
          </MantineProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  </Web3ReactProvider>
)

export default Providers
